import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Header, ActionButton } from "../../components";

import {
  RelativeContainer,
  Brand,
  Container,
  Description,
  ImageContainer,
  ProductImage,
  Store,
  Title,
  Wrapper,
  BrandContainer,
  StoreContainer,
  ProductContainer,
  Spinner,
} from "./styles";
import { api } from "../../services/api";
import { useTryOn } from "../../contexts";

const Product: React.FC = () => {
  const { product, setProduct } = useTryOn();
  const [fetching, setFetching] = useState(true);

  const { state } = useLocation();
  const navigate = useNavigate();

  const navigateToResult = () => {
    navigate("/result", {
      state: { ...state, product: product },
    });
  };

  const selectProduct = async (productId: string) => {
    setFetching(true);
    const response = await api.get("find", {
      params: {
        product_id: productId,
      },
    });
    if (response.data) setProduct(response.data);
    setFetching(false);
  };

  useEffect(() => {
    if (state?.productId) {
      selectProduct(state.productId);
    } else {
      navigate("/discovery");
    }
  }, [state?.productId]);

  return (
    <Wrapper>
      <Header title="Virtual Try On" />
      <Container>
        {fetching ? (
          <Spinner />
        ) : (
          <ImageContainer>
            <ProductContainer>
              <ProductImage src={product?.icon} />
            </ProductContainer>
            <RelativeContainer>
              <BrandContainer>
                <Brand src={product?.brand_icon} />
              </BrandContainer>

              <StoreContainer>
                <Store src={product?.site_icon} />
              </StoreContainer>
            </RelativeContainer>
          </ImageContainer>
        )}

        <Title>{product?.collection}</Title>

        <Description>{product?.name}</Description>

        <ActionButton disabled={fetching} onClick={navigateToResult}>
          Continue
        </ActionButton>
      </Container>
    </Wrapper>
  );
};

export default Product;
