import styled from "styled-components";

export const Card = styled.a`
  display: flex;
  text-decoration: none;
  cursor: pointer;

  padding: 11.5px 28px;

  :hover {
    background: rgba(255, 255, 255, 0.25);

    transition: 0.25s;

    span {
      color: #fff;
      transition: 0.25s;
    }
  }
`;

export const ItemImage = styled.img`
  height: 56px;
  width: 56px;

  background-color: #fff;

  border-radius: 4px;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-left: 28px;
`;

export const Title = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: rgba(255, 255, 255, 0.66);
`;

export const Description = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: rgba(255, 255, 255, 0.66);
`;

export const Store = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  color: rgba(255, 255, 255, 0.66);
`;
