import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import heic2any from "heic2any";

import { Header } from "../../components";

import CloseIcon from "../../assets/close.svg";

import {
  Container,
  Description,
  Image,
  InputPlaceholder,
  Title,
  UploadContainer,
  ImageContainer,
  Button,
  Wrapper,
  ActionContainer,
  ImageAction,
} from "./styles";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import ReactGA from "react-ga4";

import { useTryOn } from "../../contexts";

const Home = () => {
  const { image, setImage } = useTryOn();
  const [uploading, setUploading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { productId } = useParams();

  const onDrop = useCallback((acceptedFiles: any) => {
    acceptedFiles.forEach((file: any) => {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        if (file.type === "image/heic") {
          setUploading(true);
          const fileURL = URL.createObjectURL(file);

          fetch(fileURL)
            .then((res) =>
              res.blob().then((blob) =>
                heic2any({
                  blob,
                  toType: "image/jpeg",
                }).then((result) => {
                  setUploading(false);
                  setImage(result);
                })
              )
            )
            .catch((e) => {
              console.log(e);
            });
        } else {
          setImage(file);
        }
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  useEffect(() => {
    if (!productId) return;
    if (!location.state) location.state = {};
    location.state.productId = productId;
  }, [productId]);

  const removeImage = () => {
    setImage(undefined);
  };

  const sendImage = () => {
    if (productId) {
      navigate("/product", {
        state: {
          image: image,
          productId: productId || (location.state && location.state.productId),
        },
      });
    } else {
      navigate("/discovery");
    }
  };

  const uploadImage = () => {
    ReactGA.event({
      category: "user",
      action: "upload_image",
    });
  };

  return (
    <Wrapper>
      <Header title="Getting started" />

      <Container>
        <Title>Upload an image of yourself</Title>

        <Description>
          This is the photo we’ll use to help you try on the products. Make sure
          it’s a full body picture with head to toe in frame for the best
          results.
        </Description>

        <ImageContainer>
          {image ? (
            <div>
              <ActionContainer>
                <ImageAction onClick={removeImage}>
                  <img src={CloseIcon} alt="" />
                </ImageAction>
              </ActionContainer>
              <Image
                className="image-preview"
                src={`${URL.createObjectURL(image)}`}
                alt=""
              />
            </div>
          ) : uploading ? (
            <InputPlaceholder>Loading image...</InputPlaceholder>
          ) : (
            <UploadContainer {...getRootProps()}>
              <InputPlaceholder>Drop Image Here</InputPlaceholder>

              <InputPlaceholder light>- or -</InputPlaceholder>

              <InputPlaceholder>
                <input
                  {...getInputProps()}
                  accept="image/jpeg, image/png, image/jpg, image/heif, .heic, .heif"
                />
                Click to Upload
              </InputPlaceholder>
            </UploadContainer>
          )}
        </ImageContainer>

        <Button disabled={!image} onClick={sendImage}>
          Continue
        </Button>
      </Container>
    </Wrapper>
  );
};

export default Home;
