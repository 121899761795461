import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ActionButton, Header } from "../../components";
import {
  Container,
  Description,
  TextContainer,
  Title,
  Wrapper,
} from "./styles";

const Error: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const navigateToHome = () => {
    navigate("/", { state: state });
  };

  const tryAgain = () => {
    navigate("/result", { state: state });
  };

  return (
    <Wrapper>
      <Header title="Virtual Try On" />
      <Container>
        <TextContainer>
          <Title>Oops!</Title>

          <Description>
            Sorry, but it looks like the fitting rooms are currently occupied.
            Please try again when the traffic calms down!
          </Description>
        </TextContainer>

        <ActionButton onClick={tryAgain}>Retry</ActionButton>
        <ActionButton dark onClick={navigateToHome}>
          Continue to Home
        </ActionButton>
      </Container>
    </Wrapper>
  );
};

export default Error;
