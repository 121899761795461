import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { ReactComponent as HomeIcon } from "../../assets/home.svg";
import { ReactComponent as SearchIcon } from "../../assets/search.svg";

import { Container, StyledLink } from "./styles";

const BottomTab = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // console.log(lo);

  return (
    <Container>
      <StyledLink
        onClick={() => navigate(-1)}
        active={location.pathname !== "/discovery"}
        disabled={location.pathname !== "/discovery"}
        hidden={location.pathname === "/fullscreen"}
      >
        <HomeIcon stroke={"#fff"} />
      </StyledLink>
      <StyledLink
        onClick={() => navigate("/discovery")}
        active={location.pathname === "/discovery"}
        disabled={location.pathname === "/discovery"}
        hidden={location.pathname === "/fullscreen"}
      >
        <SearchIcon stroke={"#fff"} />
      </StyledLink>
    </Container>
  );
};

export default BottomTab;
