import React from "react";
import ReactGA from "react-ga4";
import { Provider, ErrorBoundary } from "@rollbar/react";

import { Router } from "./routes";
import { ContextProvider } from "./contexts";

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID || "");

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
  environment: process.env.REACT_APP_ROLLBAR_ENV,
};

function App() {
  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        <ContextProvider>
          <Router />
        </ContextProvider>
      </ErrorBoundary>
    </Provider>
  );
}

export default App;
