import styled from "styled-components";
import { Tooltip } from "react-tooltip";

interface ActionButtonProps {
  largeImage?: boolean;
}

interface SwitchButtonProps {
  visible?: boolean;
}

interface MiniatureProps {
  active: boolean;
}

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: #222222;
  padding-bottom: 28px;
`;

export const ImageContainer = styled.div`
  /* background-color: #191919; */
  border-radius: 8px;

  margin: 24px 0px;

  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100%;

  gap: 40px;

  position: relative;
`;

export const StyledImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  transition: 0.5s;
`;

export const MiniatureContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  min-width: 64px;
  padding: 4px;
  margin-left: 16px;

  gap: 16px;
`;

export const Miniature = styled.img<MiniatureProps>`
  width: 64px;
  height: 64px;

  border-radius: 4px;
  object-fit: contain;

  opacity: ${({ active }) => (active ? 1 : 0.25)};
  box-shadow: ${({ active }) => active && "0 0 0 4px #fff"};
  transition: 0.5s;

  cursor: pointer;
`;

export const SwitchImageLeftButton = styled.button<SwitchButtonProps>`
  width: 40px;
  height: 40px;

  border-radius: 100px;
  border: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  opacity: 0.5;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
  visibility: ${({ visible }) => !visible && "hidden"};

  transition: 0.25s;

  img {
    transform: rotate(180deg);

    width: 12px;
    height: 20px;
  }

  &:hover {
    opacity: 1;
  }
`;

export const SwitchImageRightButton = styled.button<SwitchButtonProps>`
  width: 40px;
  height: 40px;

  border-radius: 100px;
  border: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 0.5;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
  visibility: ${({ visible }) => !visible && "hidden"};

  transition: 0.25s;

  cursor: pointer;

  img {
    width: 12px;
    height: 20px;
  }

  &:hover {
    opacity: 1;
  }
`;

export const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  /* align-items: center; */
  margin: 0 auto;
  /* height: 100%; */

  width: 100%;
  max-width: 350px;
`;

export const ActionButton = styled.a<ActionButtonProps>`
  width: 40px;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 0;

  background: rgba(51, 51, 51, 0.5);
  border-radius: 100px;

  cursor: pointer;

  img {
    width: ${({ largeImage }) => (largeImage ? 35 : 20)}px;
    height: ${({ largeImage }) => (largeImage ? 35 : 20)}px;
  }

  &:hover {
    background: linear-gradient(266.57deg, #7000ff 0%, #6117ff 100%), #000000;
  }
`;

export const CustomTooltip = styled(Tooltip)`
  background: rgba(0, 0, 0, 0.8);
  border-radius: 4px;

  font-weight: 700;
  font-size: 16px;
  line-height: 19px;

  max-width: 200px;
  text-align: center;

  color: #d9d9d9;
  z-index: 2;
`;

export const LargeImageWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  gap: 28px;
  margin-right: 8px;
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  max-height: 80%;

  background: #191919;
  border-radius: 4px;

  display: flex;
  justify-content: center;
`;

export const InfoContainer = styled.div`
  position: absolute;
  left: 16px;
  top: 16px;

  overflow: visible;

  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Info = styled.img`
  width: 25px;
  height: 25px;

  z-index: 2;
`;

export const ImageTitle = styled.span`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 6px 12px;
  gap: 8px;
  background: #6117ff;
  border: 2px solid #ffffff;
  border-radius: 80px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  color: #ffffff;
`;
