import React, { useEffect, useState } from "react";

import { Header, ItemCard } from "../../components";

import { Container, Wrapper } from "./styles";
import { api } from "../../services/api";

// import { Container } from './styles';

const Discovery = () => {
  const [productList, setProductList] = useState<any>([]);

  const getProducts = async () => {
    try {
      const sneakers = (await api.get("all-products")).data;
      if (sneakers) setProductList(sneakers);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <Wrapper>
      <Header title="Discover" />
      <Container>
        {productList.map((product: any) => (
          <ItemCard
            title={product.collection}
            description={product.name}
            image={product.icon}
            store={product.store}
            url={"/try/" + product.id}
          />
        ))}
      </Container>
    </Wrapper>
  );
};

export default Discovery;
