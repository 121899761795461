import styled from "styled-components";

const imageMaxHeight = 380;
const imageMaxWidth = 352;

interface SlideProps {
  index: number;
  imageWidth: number;
}

interface IndexIndicatorProps {
  activeIndex: boolean;
}

export const SlideContainer = styled.div`
  min-height: 350px;
  flex: 1;
  position: relative;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const Slide = styled.div<SlideProps>`
  display: flex;
  gap: ${({ imageWidth }) => 2 + (imageMaxHeight - imageWidth) * 0.5}px;
  justify-content: flex-start;
  padding-left: ${({ imageWidth }) =>
    10 + (imageMaxHeight - imageWidth) * 0.5}px;

  transform: translateX(
    -${({ index, imageWidth }) => ((imageMaxHeight - imageWidth) * 0.5 + imageWidth + 2) * index}px
  );
  transition: 500ms;

  background: #191919;
  border-radius: 8px;
`;

export const StyledImage = styled.img`
  display: block;
  min-height: ${imageMaxHeight}px;
  max-height: ${imageMaxHeight}px;

  min-width: auto;
  max-width: ${imageMaxWidth}px;
  border-radius: 8px;

  overflow: hidden;
  object-fit: cover;
`;

export const SwitchImageLeftButton = styled.a`
  width: 48px;
  height: 48px;

  border-radius: 100px;
  border: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  left: 12px;
  top: calc(50% - 24px);

  cursor: pointer;

  img {
    transform: rotate(180deg);
  }
`;

export const SwitchImageRightButton = styled.a`
  border-radius: 100px;
  border: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  right: 0px;
  bottom: 0px;

  cursor: pointer;
`;

export const IndexIndicatorContainer = styled.div`
  display: flex;
  gap: 8px;
  padding-top: 16px;

  width: 100%;
  justify-content: center;
`;

export const IndexIndicator = styled.div<IndexIndicatorProps>`
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background: #fff;

  opacity: ${({ activeIndex }) => (activeIndex ? 1 : 0.25)};
`;
