import React, { useEffect, useRef, useState } from "react";
// import Carousel from "react-bootstrap/Carousel";
import { Tooltip } from "react-tooltip";

import { Header, Loading, Carousel } from "../../components";

import RegenerateIcon from "../../assets/regenerate.svg";
import SaveIcon from "../../assets/save.svg";
import EditIcon from "../../assets/edit.svg";
import DirectionIcon from "../../assets/chevron-right.svg";
import FullScreenIcon from "../../assets/fullscreen.svg";
import InfoIcon from "../../assets/info.svg";

import {
  Container,
  Description,
  Title,
  Wrapper,
  ActionButton,
  ActionContainer,
  CustomTooltip,
  IndexIndicatorContainer,
  SlideContainer,
  IndexIndicator,
  SwitchImageRightButton,
  SwitchImageLeftButton,
  StyledImage,
  Slide,
  Info,
  ImageContainer,
  FullScreenButton,
} from "./styles";
import { useLocation, useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

import { useTryOn } from "../../contexts";

// import { Container } from './styles';

const Result: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [index, setIndex] = useState(0);
  const { imageList, setImageList, fetchingResult, getResult, currentTask } = useTryOn();
  const [slideWidth, setSlideWidth] = useState(320);
  const slideRef = useRef<any>(0);

  const prevSlide = () => {
    setIndex((i) => i - 1);
  };

  const nextSlide = () => {
    setIndex((i) => i + 1);
  };

  const getData = async () => {
    ReactGA.event({
      category: "user",
      action: "get_results",
    });
    getResult(state.product.id).catch((e) => {
      console.log(e);
      ReactGA.event({
        category: "error",
        action: "get_results_error",
      });
      navigate("/error", { state });
    });
  };

  const navigateToHome = () => {
    ReactGA.event({
      category: "user",
      action: "use_new_image",
    });
    setImageList([]);
    navigate("/", { state });
  };

  useEffect(() => {
    if (imageList.length === 0 && !fetchingResult) {
      getData();
    }
  }, []);

  return (
    <Wrapper>
      <Header title="Virtual Try On" />

      {fetchingResult ? (
        <Loading/>
      ) : (
        <Container>
          <SlideContainer>
            <Slide index={index} imageWidth={slideWidth}>
              {imageList.map((slide, index) => (
                <StyledImage
                  ref={slideRef}
                  src={slide}
                  key={index}
                  onLoad={() => {
                    setSlideWidth(slideRef.current.offsetWidth);
                  }}
                />
              ))}
            </Slide>

            {/*<Info*/}
            {/*  src={InfoIcon}*/}
            {/*  data-tooltip-id="info-tooltip"*/}
            {/*  data-tooltip-content="We generate multiple images in case of errors"*/}
            {/*  data-tooltip-place="top"*/}
            {/*  alt=""*/}
            {/*/>*/}
            {/*<CustomTooltip id="info-tooltip" />*/}

            {/*<IndexIndicatorContainer>*/}
            {/*  {imageList.map((slide, i) => (*/}
            {/*    <IndexIndicator activeIndex={index === i} key={i} />*/}
            {/*  ))}*/}
            {/*</IndexIndicatorContainer>*/}
          </SlideContainer>

          {/* <Carousel slides={imageList} getIndex={setIndex} /> */}

          <CustomTooltip id="result-tooltip" />
          <CustomTooltip id="save-tooltip" />
          <CustomTooltip id="edit-tooltip" />
          <ActionContainer>
            {index > 0 && (
              <SwitchImageLeftButton onClick={prevSlide}>
                <img src={DirectionIcon} alt="" />
              </SwitchImageLeftButton>
            )}

            <FullScreenButton
              to={"/fullscreen"}
              target="_blank"
              state={imageList}
              data-tooltip-id="edit-tooltip"
              data-tooltip-content="Full Screen Mode"
              data-tooltip-place="top"
            >
              <img
                src={FullScreenIcon}
                alt=""
                style={{ width: 35, height: 35 }}
              />
              <CustomTooltip id="edit-tooltip" />
            </FullScreenButton>

            <ActionButton
              data-tooltip-id="edit-tooltip"
              data-tooltip-content="Edit Reference Image"
              data-tooltip-place="top"
              onClick={navigateToHome}
            >
              <img src={EditIcon} alt="" />
            </ActionButton>

            <ActionButton
              onClick={getData}
              data-tooltip-id="result-tooltip"
              data-tooltip-content="Regenerate"
              data-tooltip-place="top"
            >
              <img src={RegenerateIcon} alt="" />
            </ActionButton>

            {index < imageList.length - 1 && (
              <SwitchImageRightButton onClick={nextSlide}>
                <img src={DirectionIcon} alt="" />
              </SwitchImageRightButton>
            )}
          </ActionContainer>
        </Container>
      )}
    </Wrapper>
  );
};

export default Result;
