import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
  useEffect,
} from "react";
import imageCompression from "browser-image-compression";
import {v4 as uuidv4} from 'uuid';

import { postSneakerImage } from "../services";
import { api } from "../services/api";
import {getImageUrl, uploadImage} from "../services/images";

interface TryOnProps {
  children: ReactNode;
}

interface TryOnPropsContextProps {
  image?: Blob | MediaSource;
  setImage: Dispatch<SetStateAction<any>>;
  product?: {
    icon: string;
    brand_icon: string;
    site_icon: string;
    collection: string;
    name: string;
  };
  setProduct: Dispatch<SetStateAction<undefined>>;
  imageList: string[];
  setImageList: Dispatch<SetStateAction<string[]>>;
  fetchingResult: boolean;
  getResult: (productId: string) => Promise<void>;
  currentTask: {
    status: string;
    queue: number;
  };
}

const TryOnContext = createContext({} as TryOnPropsContextProps);

function TryOnProvider({ children }: TryOnProps) {
  const [image, setImage] = useState<File>();
  const [product, setProduct] = useState(undefined);
  const [imageList, setImageList] = useState<string[]>([]);
  const [fetchingResult, setFetchingResult] = useState(false);
  const [currentTask, setCurrentTask] = useState({
    status: "",
    queue: 0,
  });

  const imageOptions = {
    maxWidthOrHeight: 1024,
  };

  function base64ToImage(base64String: string, filename: string) {
    var byteCharacters = window.atob(base64String);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);
    var blob = new Blob([byteArray], { type: "image/png" });
    var url = URL.createObjectURL(blob);
    return url;
  }

  const formatImages = (urlList: [any]) => {
    if (urlList && urlList.length > 0) {
      setImageList(urlList)
      localStorage.setItem("imageList", JSON.stringify(urlList));
      console.log(JSON.stringify(urlList))
    } else {
      setFetchingResult(false);
    }
  };

  const getResult = async (productId: string) => {
    try {
      setFetchingResult(true);

      if (image) {
        const compressedFile = await imageCompression(image, imageOptions);

        localStorage.setItem(
          "originalImage",
          JSON.stringify(URL.createObjectURL(image))
        );

        const filename = uuidv4()
        await uploadImage(compressedFile, filename)
        const imageUrl = await getImageUrl(filename)
        const task_output = await postSneakerImage(imageUrl, productId);
        console.log(task_output.data);
        fetchData(task_output.data["task_id"]);
      }
    } catch (e) {
      setFetchingResult(false);
      console.log(e);
      throw new Error();
    }
  };

  const fetchData = (taskId: string) => {
    const eventSource = new EventSource(`${api.getUri()}task/${taskId}/status`);
    eventSource.addEventListener("update", (e) => {
      const parsedData = JSON.parse(e.data);
      console.log(parsedData);
      setCurrentTask(parsedData);

      if (parsedData["status"] === "SUCCESS") {
        fetchOutput(taskId).catch((e) => {
          throw new Error();
        });
        eventSource.close();
      }
    });
  };

  const fetchOutput = async (taskId: string) => {
    const response = (await api.get(`task/${taskId}/output`)).data;
    const result = response["task_result"];
    if (result["error"]) {
      console.log(response["task_result"]["error"]);
      setFetchingResult(false);
      throw new Error();
    } else if (result["url"]) {
      formatImages([result["url"]]);
      setFetchingResult(false);
    }
  };
  return (
    <TryOnContext.Provider
      value={{
        image,
        setImage,
        product,
        setProduct,
        imageList,
        setImageList,
        fetchingResult,
        getResult,
        currentTask,
      }}
    >
      {children}
    </TryOnContext.Provider>
  );
}

function useTryOn() {
  const context = useContext(TryOnContext);

  return context;
}

export { TryOnProvider, useTryOn };
