import styled from "styled-components";

export const Container = styled.div`
  background: #111111;
  width: 100%;
  min-height: 56px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.h1`
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 19px;

  color: #ffffff;

  padding-left: 13px;
  margin: 0;
`;

export const CloseButton = styled.img`
  padding-right: 21px;
  cursor: pointer;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  padding-left: 16px;
  flex: 1;

  img {
    height: 24px;
    width: 24px;
    cursor: pointer;
  }
`;
