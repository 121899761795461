import { api } from "./api";

export const uploadImage = async (file: any, name: string) => {
    const response = await api.get(
        "images/presigned-upload",
        {
            params: {
                name: name
            }
        }
    );
    const url = response.data.url
    console.log(url)
    const uploadResponse = await api.put(url, file, {
        headers: {
            'Content-Type': file.type
        }
    });
    console.log(uploadResponse)
    return uploadResponse;
}

export const getImageUrl = async (name: string) => {
    const response = await api.get(
        "images/presigned-url",
        {
            params: {
                name: name
            }
        }
    );
    console.log("dl from " + response.data.url)
    return response.data.url;
}

export const postSneakerImage = async (file: any, productId: string) => {
  const response = await api.post(
    "wear/sneakers",
    { image_url: file, product_id: productId },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response;
};
