import { BrowserRouter, Route, Routes } from "react-router-dom";
import { BottomTab } from "../components";
import { Home, Product, Result, Error, Discovery, FullScreen } from "../pages";

export const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/try/:productId" element={<Home />} />
        <Route path="/product" element={<Product />} />
        <Route path="/result" element={<Result />} />
        <Route path="/error" element={<Error />} />
        <Route path="/discovery" element={<Discovery />} />
        <Route path="/fullscreen" element={<FullScreen />} />
      </Routes>
      <BottomTab />
    </BrowserRouter>
  );
};
