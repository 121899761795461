import React, { useCallback, useEffect, useState } from "react";

import { Header } from "../../components";

import SaveIcon from "../../assets/save.svg";
import DirectionIcon from "../../assets/chevron-right.svg";
import InfoIcon from "../../assets/info.svg";

import {
  Container,
  ActionButton,
  CustomTooltip,
  StyledImage,
  SwitchImageLeftButton,
  SwitchImageRightButton,
  ImageContainer,
  ActionButtonsContainer,
  Miniature,
  MiniatureContainer,
  LargeImageWrapper,
  Wrapper,
  ImageTitle,
  Info,
  InfoContainer,
} from "./styles";

const FullScreen = () => {
  const imageList = JSON.parse(
    localStorage.getItem("imageList") ?? ""
  ) as string[];
  const originalImage = JSON.parse(localStorage.getItem("originalImage") ?? "");
  const [index, setIndex] = useState(0);

  const images = [...imageList, originalImage];

  const navigateSlide = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "ArrowRight") {
        if (index < images.length - 1) {
          setIndex((i) => i + 1);
        }
      }
      if (e.key === "ArrowLeft") {
        if (index > 0) {
          setIndex((i) => i - 1);
        }
      }
    },
    [index, images.length]
  );

  useEffect(() => {
    window.addEventListener("keydown", navigateSlide);

    return () => {
      window.removeEventListener("keydown", navigateSlide);
    };
  }, [navigateSlide]);

  return (
    <Container>
      <Header title="Virtual Try On" />

      <ImageContainer>
        <MiniatureContainer>
          {images.map((image, i) => (
            <Miniature
              onClick={() => setIndex(i)}
              active={index === i}
              src={image}
            />
          ))}
        </MiniatureContainer>

        <LargeImageWrapper>
          <Wrapper>
            <InfoContainer>
              <ImageTitle>
                {index === images.length - 1
                  ? "Original"
                  : `Version ${index + 1}`}
              </ImageTitle>

              {/*<Info*/}
              {/*  src={InfoIcon}*/}
              {/*  data-tooltip-id="info-tooltip"*/}
              {/*  data-tooltip-content="We generate multiple images in case of errors"*/}
              {/*  data-tooltip-place="top"*/}
              {/*  alt=""*/}
              {/*/>*/}
              {/*<CustomTooltip id="info-tooltip" />*/}
            </InfoContainer>
            <StyledImage
              // ref={slideRef}
              src={images[index]}
              // key={index}
              // onLoad={() => {
              //   setSlideWidth(slideRef.current.offsetWidth);
              // }}
            />
          </Wrapper>

          <ActionButtonsContainer>
            <SwitchImageLeftButton
              visible={index > 0}
              onClick={() => setIndex((i) => i - 1)}
            >
              <img src={DirectionIcon} alt="" />
            </SwitchImageLeftButton>

            <ActionButton
              data-tooltip-id="save-tooltip"
              data-tooltip-content="Save Image"
              data-tooltip-place="top"
              href={images[index]}
              download
            >
              <img src={SaveIcon} alt="" />
              <CustomTooltip id="save-tooltip" />
            </ActionButton>

            <SwitchImageRightButton
              visible={index < images.length - 1}
              onClick={() => setIndex((i) => i + 1)}
            >
              <img src={DirectionIcon} alt="" />
            </SwitchImageRightButton>
          </ActionButtonsContainer>
        </LargeImageWrapper>
      </ImageContainer>
    </Container>
  );
};

export default FullScreen;
