import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  flex: 1;
  display: flex;
  flex-direction: column;

  position: relative;

  /* padding-top: 12px; */
  /* gap: 25px; */

  background: #222222;
`;

export const Wrapper = styled.div`
  width: 400px;
  height: 540px;

  flex-direction: column;
  align-items: center;

  background: #222222;
  overflow-y: scroll;
`;
