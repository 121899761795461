import { Link } from "react-router-dom";
import styled from "styled-components";

interface InputPlaceholderType {
  light?: boolean;
}

interface ButtonProps {
  disabled?: boolean;
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 22px 24px;

  background: #222222;
  overflow-x: hidden;
`;

export const Wrapper = styled.div`
  width: 400px;
  height: calc(100% - 60px);

  /* max-height: 600px; */

  display: flex;
  flex-direction: column;
  align-items: center;

  background: #222222;
`;

export const Title = styled.h1`
  width: 100%;
  font-weight: 800;
  font-size: 18px;
  line-height: 22px;

  margin: 0;
  padding-bottom: 8px;

  color: #ffffff;
`;

export const Description = styled.span`
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;

  margin: 0;

  color: #ffffff;
`;

export const ImageContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 352px;
  min-height: 280px;
  height: 280px;

  margin: 22px 0px;
  border-radius: 8px;

  box-sizing: border-box;
`;

export const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.25);
  cursor: pointer;
`;

export const InputPlaceholder = styled.span<InputPlaceholderType>`
  font-weight: ${({ light }) => (light ? 600 : 400)};
  font-size: 14px;
  line-height: 17px;
  padding: 4px 0px;

  text-align: center;

  color: #ffffff;

  opacity: ${({ light }) => (light ? 0.75 : 0.5)};
`;

export const Button = styled.button<ButtonProps>`
  text-decoration: none;
  :hover {
    text-decoration: none;
    color: #d9d9d9;
  }

  display: flex;
  justify-content: center;
  align-items: center;

  border: 0;
  width: 100%;
  max-width: 352px;
  min-height: 40px;
  height: 40px;

  background: linear-gradient(266.57deg, #7000ff 0%, #6117ff 100%);
  border-radius: 4px;
  cursor: ${({ disabled }) => !disabled && "pointer"};

  font-weight: 700;
  font-size: 14px;
  line-height: 17px;

  color: #d9d9d9;

  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

export const ActionContainer = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;

  display: flex;
  align-items: center;
  gap: 8px;

  :hover ~ img {
    opacity: 0.5;
  }
`;

export const ImageAction = styled.div`
  width: 32px;
  height: 32px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: #000000;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 41px;
  cursor: pointer;

  transition: 0.1s;

  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
    border: 1px solid #ffffff;
  }
`;

export const Image = styled.img`
  height: 280px;
  max-height: 280px;

  width: auto;
  max-width: 352px;

  overflow: hidden;
  object-fit: cover;

  transition: 0.2s;

  /* cursor: pointer; */
`;
